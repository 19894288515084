<template>
    <div id="app">
        <div class="top-box">
            <top-component />
        </div>
        <div class="center-box">
            <div class="left-box">
                <left-component />
            </div>
            <div class="main-box">
                <router-view />
            </div>
        </div>
        <div class="foot-box">
            <i class="el-icon-lock"></i>
            <span> 版权所有,翻版必究 </span>
        </div>
    </div>
</template>

<script>
import TopVue from "./components/Top.vue"
import LeftVue from "./components/Left.vue"
export default {
    name: "AppView",
    data() {
        return {
            showTab: true
        }
    },
    components: {
        "top-component": TopVue,
        "left-component": LeftVue,
    },
    created() {
    },
    mounted() { },

}
</script>

<style lang="less">
body {
    margin: 0px;
    padding: 0px;
}

#app {
    height: calc(100vh);
    width: calc(100vw);
    background-color: white;
    display: flex;
    flex-direction: column;
    .top-box {
        width: calc(100vw);
        height: calc(10vh);
        background-color: white;
        border-bottom: 1px solid #cccbcb;
        box-sizing: border-box;
        box-shadow: 1px 1px 7px #cccbcb;
    }
    .center-box {
        width: calc(100vw);
        display: flex;
        .left-box {
            width: calc(10vw);
            height: calc(85vh);
            background-color: #263238;
        }
        .main-box {
            width: calc(80vw);
            height: calc(78vh);
            background-color: white;
            display: flex;
            justify-content: left;
            margin-left: calc(5vw);
            margin-top: calc(3vh);
            box-shadow: 1px 1px 7px #cccbcb;
            padding: 10px;
            overflow: auto;
        }
    }
    .foot-box {
        width: calc(100vw);
        height: calc(5vh);
        background-color: #f4f6f7;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
